import { Button, Col, DatePicker, Row, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { coreApi } from "../../api/calls";

const Dac7YearlyExport = () => {
  const { t } = useTranslation();
  const [year, setYear] = useState(moment().subtract(1, "year").year());
  const [isLoadingQuarterly, setLoadingQuarterly] = useState(false);
  const [isLoadingMonthly, setLoadingMonthly] = useState(false);

  const handleDownload = (type) => {
    const setLoading = type === "quarterly" ? setLoadingQuarterly : setLoadingMonthly;
    const endpoint = type === "quarterly" ? "/dac7/export/yearly-report/" : "/dac7/export/yearly-monthly-report/";

    setLoading(true);
    coreApi
      .get(`${endpoint}${year}`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        const fileName = type === "quarterly"
          ? `dac7-yearly-export-${year}-quarterly.csv`
          : `dac7-yearly-export-${year}-monthly.csv`;
        fileDownload(data, fileName);
        notification.success({ message: t("exports.success") });
      })
      .catch((err) =>
        notification.error({
          message: err.response?.data?.message || t("exports.error"),
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Row gutter={16} align="middle" justify={"start"}>
      <Col span={4}>
        <DatePicker
          picker="year"
          value={moment(year, "YYYY")}
          onChange={(date) => setYear(date ? date.year() : year)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={6} >
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          loading={isLoadingQuarterly}
          onClick={() => handleDownload("quarterly")}
        >
          {t("exports.exportQuarterly")}
        </Button>
      </Col>
      <Col span={4} gutter={[16, 16]}>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          loading={isLoadingMonthly}
          onClick={() => handleDownload("monthly")}
        >
          {t("exports.exportMonthly")}
        </Button>
      </Col>
    </Row>
  );
};

export default Dac7YearlyExport;
